import React from 'react';
import { ModerationRequest } from 'src/types';
import ModerationRequestItemCreatorApplication from 'src/components/moderation-request-item/types/creator-application';
import ModerationRequestItemPostApprovalRequest from 'src/components/moderation-request-item/types/post-approval-request';
import ModerationRequestItemPostModerationRequest from 'src/components/moderation-request-item/types/post-moderation-request';
import ModerationRequestItemUserModerationRequest from 'src/components/moderation-request-item/types/user-moderation-request';
import ModerationRequestItemCuratedListModerationRequest from 'src/components/moderation-request-item/types/curated-list-moderation-request';
import ModerationRequestItemCommentModerationRequest from 'src/components/moderation-request-item/types/comment-moderation-request';

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemView: React.FC<InterfaceProps> = ({
  moderationRequest,
}) => {
  switch (moderationRequest.type) {
    case 0: {
      return (
        <ModerationRequestItemCreatorApplication
          moderationRequest={moderationRequest}
        />
      );
    }
    case 2: {
      return (
        <ModerationRequestItemPostModerationRequest
          moderationRequest={moderationRequest}
        />
      );
    }
    case 3: {
      return (
        <ModerationRequestItemCuratedListModerationRequest
          moderationRequest={moderationRequest}
        />
      );
    }
    case 4: {
      return (
        <ModerationRequestItemUserModerationRequest
          moderationRequest={moderationRequest}
        />
      );
    }
    case 5: {
      return (
        <ModerationRequestItemCommentModerationRequest
          moderationRequest={moderationRequest}
        />
      );
    }
    case 6: {
      return (
        <ModerationRequestItemPostApprovalRequest
          moderationRequest={moderationRequest}
        />
      );
    }
    default:
      return <></>;
  }
};

export default ModerationRequestItemView;
