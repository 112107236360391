import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import {
  StyledButton,
  ButtonSet,
  DeemphasizedButton,
  StyledError,
} from 'src/styles';
import { useTranslation } from 'react-i18next';
import { Form, FieldArray, FormikErrors } from 'formik';
import { PostAccessType, PostInput, Role } from 'src/types';
import {
  SimpleInput,
  SimpleTextarea,
  SimpleAutocomplete,
  SimpleDate,
} from 'src/components/simple-form';
import styled from 'styled-components';
import { fibonacci } from 'src/utils/math';
import { USER_SUGGESTIONS_QUERY } from 'src/queries';
import { CreditInput, GenericOptionItem, User } from 'src/types';
import { AutocompleteFieldUserSuggestion } from 'src/components/autocomplete-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBlocker } from 'react-router-dom';
import { useConfirmationModal } from 'src/hooks/use-confirmation-modal';
import DateField from 'src/components/date-field';

const Container = styled.div`
  margin-top: ${fibonacci(3)}rem;
`;

const CreditsPrompt = styled.a`
  display: block;
  margin: ${fibonacci(3)}rem 0;
  text-align: center;
`;

const CenteredButton = styled(StyledButton)`
  margin: ${fibonacci(3)}rem auto;
`;

const CreditElement = styled.div`
  display: flex;
  margin-top: ${fibonacci(3)}rem;
`;

const DeleteCreditButton = styled(DeemphasizedButton)`
  flex-grow: 0;
  margin-top: 0.4rem;
  height: ${fibonacci(5)}rem;
`;

interface InterfaceProps {
  dirty: boolean;
  errors: FormikErrors<PostInput>;
  globalError: string | null;
  hasSaved: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  values: PostInput;
  back: () => void;
}

const PostInnerFinalizeForm: React.FC<InterfaceProps> = ({
  dirty,
  errors,
  globalError,
  hasSaved,
  isSubmitting,
  isValid,
  values,
  back,
}) => {
  const { t } = useTranslation('general');
  const [hasCollaborators, setHasCollaborators] = useState(
    values.credits.length > 1
  );
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      currentLocation.pathname !== nextLocation.pathname && dirty && !hasSaved
    );
  });
  const confirm = useConfirmationModal();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(t('formCancel'), blocker);
    }
  }, [blocker.state]);

  // TODO: for some reason, the errors.mediaCollection.mediaItems array
  // keeps coming back as [null], resulting in `isValid` being false.
  // this solution is hacky af, but i need to move on for now
  if (
    Array.isArray(errors.mediaCollection?.mediaItems) &&
    (errors.mediaCollection?.mediaItems as string[]).filter((mi) => !!mi)
      .length === 0 &&
    Object.keys(errors).length === 1
  ) {
    isValid = true;
  }

  return (
    <Container>
      <Form>
        <SimpleDate
          label={t('postsPublishAt')}
          name="publishAt"
          minDate={new Date()}
          showMonthDropdown
          showYearDropdown
        />
        <SimpleInput label={t('postsTitle')} name="title" />
        <SimpleTextarea
          label={t('postsDescription')}
          name="description"
          placeholder={t('postsDescriptionDetail')}
        />
        <FieldArray name="credits">
          {(arrayHelpers): ReactElement => {
            const evenlyDistrubteShares = (credits: CreditInput[]) => {
              const totalCredits = credits.length;
              const shareWithRemainder = 100 / totalCredits;
              const baseShare = Math.floor(shareWithRemainder);
              const remainder = shareWithRemainder - baseShare;
              const fullShares = Array.from(
                { length: totalCredits },
                () => baseShare
              );
              let extraShares = remainder * totalCredits;
              for (
                let i = 0;
                extraShares > 1;
                i + 1 > totalCredits - 1 ? (i = 0) : (i = i + 1)
              ) {
                fullShares[i]++;
                extraShares--;
              }

              fullShares.forEach((share, i) => {
                arrayHelpers.form.setFieldValue(
                  `credits.${arrayHelpers.form.values.credits.indexOf(
                    credits[i]
                  )}.share`,
                  share
                );
              });

              return fullShares;
            };
            const addCredit = (): void => {
              const newCredit = {
                canModify: false,
                isPublisher: false,
                role: Role.performer,
                share: 0,
                title: '',
                username: '',
              };
              const shares = evenlyDistrubteShares([
                ...values.credits,
                newCredit,
              ]);
              newCredit.share = shares[shares.length - 1];
              arrayHelpers.insert(values.credits.length, newCredit);
            };

            if (!hasCollaborators) {
              return (
                <CreditsPrompt
                  onClick={(): void => {
                    addCredit();
                    setHasCollaborators(true);
                  }}
                >
                  {t('postsOthersWereInvolved')}
                </CreditsPrompt>
              );
            } else {
              return (
                <>
                  {values.credits.map((credit, index) => {
                    const onCreditChange = (
                      event: ChangeEvent<HTMLInputElement>
                    ): void => {
                      let value = parseInt(event.target.value);
                      if (isNaN(value)) value = 0;
                      value = Math.max(0, Math.min(100, value));
                      const name = `credits.${index}.share`;
                      const reduceNeighbor = (
                        amount: number,
                        distance: number
                      ) => {
                        const neighborIndex =
                          index + distance > values.credits.length - 1
                            ? distance - (values.credits.length - index)
                            : index + distance;
                        const projectedNewValue =
                          values.credits[neighborIndex].share - amount;
                        const actualNewValue = Math.min(
                          Math.max(projectedNewValue, 0),
                          100
                        );
                        arrayHelpers.form.setFieldValue(
                          `credits.${neighborIndex}.share`,
                          actualNewValue
                        );

                        return actualNewValue - projectedNewValue;
                      };

                      const previousValue = values.credits[index].share;
                      let diff = value - previousValue;
                      let distance = 1;

                      while (diff !== 0) {
                        diff = reduceNeighbor(diff, distance);
                        distance++;
                      }

                      arrayHelpers.form.setFieldValue(name, value);
                    };
                    return (
                      <CreditElement key={index}>
                        <DeleteCreditButton
                          disabled={credit.isPublisher}
                          type="button"
                          onClick={(): void => {
                            if (values.credits.length === 2) {
                              setHasCollaborators(false);
                            }
                            evenlyDistrubteShares([
                              ...values.credits.slice(0, index),
                              ...values.credits.slice(index + 1),
                            ]);
                            arrayHelpers.remove(index);
                          }}
                        >
                          <FontAwesomeIcon icon="times" />
                        </DeleteCreditButton>
                        <SimpleAutocomplete
                          autoFocus={true}
                          disabled={credit.isPublisher}
                          label={t('creditsUsername')}
                          name={`credits.${index}.username`}
                          query={USER_SUGGESTIONS_QUERY}
                          serializeEntries={(data): GenericOptionItem[] => {
                            const entries =
                              data && data.userSuggestions
                                ? data.userSuggestions
                                : [];

                            return entries.map((entry: User) => {
                              return {
                                value: entry.username,
                                label: (
                                  <AutocompleteFieldUserSuggestion
                                    user={entry}
                                  />
                                ),
                              };
                            });
                          }}
                        />
                        {values.accessType ===
                        PostAccessType['post-access-type-pay'] ? (
                          <SimpleInput
                            label={t('creditsShare')}
                            max={100}
                            min={0}
                            type="number"
                            name={`credits.${index}.share`}
                            onChange={onCreditChange}
                          />
                        ) : null}
                        <SimpleInput
                          label={t('creditsRole')}
                          name={`credits.${index}.title`}
                          placeholder={t('creditsRolePlaceholder')}
                        />
                      </CreditElement>
                    );
                  })}
                  <CenteredButton type="button" onClick={addCredit}>
                    {t('creditsAdd')}
                  </CenteredButton>
                </>
              );
            }
          }}
        </FieldArray>
        {globalError && <StyledError>{globalError}</StyledError>}
        <ButtonSet>
          <DeemphasizedButton type="button" onClick={back}>
            {t('back')}
          </DeemphasizedButton>
          <StyledButton type="submit" disabled={isSubmitting || !isValid}>
            {t('submit')}
          </StyledButton>
        </ButtonSet>
      </Form>
    </Container>
  );
};

export default PostInnerFinalizeForm;
