import React, { useState } from 'react';
import { GlobalStyleVariables } from 'src/styles/global';
import StickyBorder from 'src/components/sticky-border';
import PostRouteNav from 'src/routes/post/post-route-nav';
import PostRouteContent from 'src/routes/post/post-route-content';
import { Outlet } from 'react-router-dom';
import { Post, PostEditInput } from 'src/types';
import { NavContent } from 'src/styles';
import styled from 'styled-components';
import PostEditForm from 'src/components/post-edit-form';
import { fibonacci } from 'src/utils/math';
import { UPDATE_ALBUM_MUTATION } from 'src/queries';
import { useMutation } from '@apollo/client';
import { uploadPost } from 'src/utils/uploads';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';

const StyledPostEditForm = styled(PostEditForm)`
  margin-top: ${fibonacci(5)}rem;
`;

interface InterfaceProps {
  post: Post;
}

const PostIndexRouteView: React.FC<InterfaceProps> = ({ post }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatePostMutation] = useMutation(UPDATE_ALBUM_MUTATION);
  const reloadAndViewPost = useReloadAndViewPost(post);

  const update = async (variables: PostEditInput) => {
    await uploadPost(
      {
        id: variables.id,
        accessType: variables.accessType,
        description: variables.description,
        mediaCollection: {
          id: variables.mediaCollection.id,
          mediaItems: variables.mediaCollection.mediaItems,
        },
        price: variables.price,
        publishAt: variables.publishAt ?? new Date(),
        title: variables.title,
      },
      updatePostMutation,
      post
    );
    setIsEditing(false);
    reloadAndViewPost();
  };

  if (isEditing) {
    return (
      <StyledPostEditForm
        post={post}
        cancel={() => setIsEditing(false)}
        update={update}
      />
    );
  } else {
    return (
      <>
        <PostRouteContent post={post} setIsEditing={setIsEditing} />

        <PostRouteNav post={post} />

        <StickyBorder top={GlobalStyleVariables.headerHeight * 2} />

        <NavContent>
          <Outlet context={{ post }} />
        </NavContent>
      </>
    );
  }
};

export default PostIndexRouteView;
