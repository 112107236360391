import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { PaginationQueryVariables, Post } from 'src/types';
import { HeadingTwo, PrimaryColorLink } from 'src/styles';
import { GlobalStyleVariables } from 'src/styles/global';
import PostCardCredits from 'src/components/post-card/post-card-credits';
import PostCardMediaItem from 'src/components/post-card/post-card-media-item';
import PostCardStats from 'src/components/post-card/post-card-stats';
import PostCardAcquireButton from 'src/components/post-card/post-card-acquire-button';
import PostCardListButton from 'src/components/post-card/post-card-list-button';
import { postTitle } from 'src/utils/formatting';
import PostCardCarousel from './post-card-carousel';
import { fibonacci } from 'src/utils/math';
import ExpandingContainer from '../expanding-container';
import { parseMarkdown } from 'src/utils/security';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    width: 100%;
  }
`;

interface InfoContainerProps {
  useCarousel: boolean;
}

const InfoContainer = styled.div.attrs((props: InfoContainerProps) => ({
  useCarousel: props.useCarousel,
}))`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
  padding: 1rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: 0 1rem;
  }

  ${(props): string | undefined => {
    if (props.useCarousel) {
      return `
        margin: 0 ${fibonacci(6)}rem;

        @media ${GlobalStyleVariables.phoneMediaQuery} {
          margin: 0;
        }
      `;
    }
  }}
`;

const StyledCarouselLink = styled(PrimaryColorLink)`
  overflow: hidden;
`;

const StyledPreviewLink = styled(PrimaryColorLink)`
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Title = styled(HeadingTwo)`
  flex-grow: 1;
  font-size: ${GlobalStyleVariables.baseFontSize}rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledExpandingContainer = styled(ExpandingContainer)`
  margin-top: 1rem;
`;

interface InterfaceProps {
  className?: string;
  post: Post;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  useCarousel?: boolean;
  variables?: PaginationQueryVariables;
}

const PostCardView: React.FC<InterfaceProps> = ({
  className,
  post,
  shouldMute,
  shouldPlayOnView,
  useCarousel,
}) => {
  const location = useLocation();
  return (
    <Container className={className}>
      {useCarousel ? (
        <StyledCarouselLink to={`/posts/${post.id}`} state={location?.state}>
          <PostCardCarousel
            post={post}
            shouldMute={shouldMute}
            shouldPlayOnView={shouldPlayOnView}
          />
        </StyledCarouselLink>
      ) : (
        <StyledPreviewLink to={`/posts/${post.id}`} state={location?.state}>
          <PostCardMediaItem
            post={post}
            shouldMute={shouldMute}
            shouldPlayOnView={shouldPlayOnView}
          />
        </StyledPreviewLink>
      )}
      <InfoContainer useCarousel={useCarousel}>
        <TitleContainer>
          <Title>
            <PrimaryColorLink to={`/posts/${post.id}`} state={location?.state}>
              {postTitle(post)}
            </PrimaryColorLink>
          </Title>
          <PostCardListButton post={post} />
          <PostCardAcquireButton post={post} />
        </TitleContainer>
        <PostCardCredits credits={post.credits} />
        <PostCardStats post={post} />
        {useCarousel && (
          <StyledExpandingContainer>
            <div dangerouslySetInnerHTML={parseMarkdown(post.description)} />
          </StyledExpandingContainer>
        )}
      </InfoContainer>
    </Container>
  );
};

export default PostCardView;
