import React from 'react';
import { Notification } from 'src/types';
import NotificationItemWrapper from 'src/components/notification-item/notification-item-wrapper';
import { useNavigate } from 'react-router';
import NotificationItemModeratorComment from '../moderator-comment';
import { useTranslation } from 'react-i18next';
import { postTitle } from 'src/utils/formatting';

interface InterfaceProps {
  notification: Notification;
  onClick: () => void;
}

const NotificationItemPostApproved: React.FC<InterfaceProps> = ({
  notification,
  onClick,
}) => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  function augmentedClick(): void {
    if (notification.post) {
      navigate({
        pathname: `/posts/${notification.post.id}`,
      });
    }
    onClick();
  }

  return (
    <NotificationItemWrapper
      notification={notification}
      onClick={augmentedClick}
    >
      {t('notificationsPostApproved', { post: postTitle(notification.post) })}
      <NotificationItemModeratorComment notification={notification} />
    </NotificationItemWrapper>
  );
};

export default NotificationItemPostApproved;
