import React, { ReactElement, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { PostAccessType, PostInput, Role } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import LoadingSpinner from 'src/components/loading-spinner';
import PostInnerUploadForm from './post-inner-upload-form';
import PostInnerFilterForm from './post-inner-filter-form';
import PostInnerFinalizeForm from './post-inner-finalize-form';
import { useForceUpdate } from 'src/hooks/use-force-update';

interface InterfaceProps {
  cancel: () => void;
  submit: (variables: PostInput) => Promise<void>;
}

const PostForm: React.FC<InterfaceProps> = ({ cancel, submit }) => {
  const { user } = useAuth();
  const { t } = useTranslation('general');
  const [hasSaved, setHasSaved] = useState(false);
  const [step, setStep] = useState('upload');
  const [globalError, setGlobalError] = useState<string | null>(null);
  const forceUpdate = useForceUpdate();

  if (!user) {
    return <LoadingSpinner />;
  } else {
    const postSchema = yup.object().shape({
      description: yup.string().max(666, t('errorTooLong', { length: 666 })),
      title: yup.string().max(69, t('errorTooLong', { length: 69 })),
      price: yup.number().when('accessType', {
        is: (at: PostAccessType) =>
          at === PostAccessType['post-access-type-pay'],
        then: (schema) =>
          schema
            .required()
            .min(5, t('errorAtLeastDollarAmount', { amount: 5 })),
      }),
      credits: yup.array().of(
        yup.object().shape({
          share: yup
            .number()
            .required(t('errorRequired'))
            .integer(t('errorInteger'))
            .min(0, t('errorAtLeastAmount', { amount: 0 }))
            .max(100, t('errorAtMostAmount', { amount: 100 })),
          title: yup.string().max(69, t('errorTooLong', { length: 69 })),
          username: yup
            .string()
            .required()
            .max(69, t('errorTooLong', { length: 69 })),
        })
      ),
      mediaCollection: yup.object().shape({
        mediaItems: yup
          .array()
          .min(1, t('errorEmpty'))
          .of(
            yup.object().shape({
              order: yup.number().required().integer(),
              image: yup.object().shape({
                caption: yup
                  .string()
                  .max(69, t('errorTooLong', { length: 69 })),
              }),
              video: yup.object().shape({
                caption: yup
                  .string()
                  .max(69, t('errorTooLong', { length: 69 })),
              }),
            })
          ),
      }),
    });

    const onSubmit = async (
      variables: PostInput,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { setSubmitting }: any
    ): Promise<void> => {
      try {
        setGlobalError(null);
        const isPaid =
          variables.accessType === PostAccessType['post-access-type-pay'];
        if (!isPaid) {
          variables.price = 0;
          variables.credits.forEach((c) => (c.share = 0));
          variables.credits[0].share = 100;
        }

        variables.price = variables.price?.toString();

        const totalShares = variables.credits.reduce(
          (sum, credit) => credit.share + sum,
          0
        );

        if (totalShares === 100) {
          setHasSaved(true);
          await submit(variables);
        } else {
          setGlobalError(t('errorSharesMustEqual100'));
        }
      } catch (e) {
        console.log('error', e as Error);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Formik
        initialValues={{
          accessType: PostAccessType['post-access-type-pay'],
          description: '',
          price: 5,
          publishAt: new Date(),
          title: '',
          credits: [
            {
              canModify: true,
              isPublisher: true,
              role: Role.performer,
              share: 100,
              title: '',
              username: user.username,
            },
          ],
          mediaCollection: {
            mediaItems: [],
          },
        }}
        onSubmit={onSubmit}
        validationSchema={postSchema}
      >
        {({
          dirty,
          isSubmitting,
          isValid,
          setFieldValue,
          values,
          errors,
        }): ReactElement => {
          if (step === 'upload') {
            return (
              <PostInnerUploadForm
                dirty={dirty}
                errors={errors}
                globalError={globalError}
                hasSaved={hasSaved}
                values={values}
                cancel={cancel}
                forceUpdate={forceUpdate}
                next={() =>
                  values.accessType === PostAccessType['post-access-type-free']
                    ? setStep('finalize')
                    : setStep('filter')
                }
                setFieldValue={setFieldValue}
              />
            );
          } else if (step === 'filter') {
            return (
              <PostInnerFilterForm
                back={() => setStep('upload')}
                next={() => setStep('finalize')}
              />
            );
          } else {
            return (
              <PostInnerFinalizeForm
                dirty={dirty}
                errors={errors}
                globalError={globalError}
                hasSaved={hasSaved}
                isSubmitting={isSubmitting}
                isValid={isValid}
                values={values}
                back={() =>
                  values.accessType === PostAccessType['post-access-type-free']
                    ? setStep('upload')
                    : setStep('filter')
                }
              />
            );
          }
        }}
      </Formik>
    );
  }
};

export default PostForm;
