import {
  Role,
  Citizenship,
  MediaItem,
  User,
  PostAccessType,
  SubscriptionDiscountValidSubscriberType,
  SubscriptionDiscountType,
} from 'src/types/schema';

export interface PostInput {
  accessType: PostAccessType;
  description: string;
  price: number | string;
  publishAt: Date;
  title: string;
  credits: CreditInput[];
  mediaCollection: MediaCollectionInput;
}

export interface PostEditInput {
  id: string;
  accessType: PostAccessType;
  description: string;
  price: number | string;
  publishAt: Date;
  title: string;
  mediaCollection: MediaCollectionInput;
}

export interface CreatorApplicationInput {
  id?: string;
  birthday: Date;
  citizenship: Citizenship;
  legalName: string;
}

export interface CommentInput {
  id?: string;
  text: string;
  postId: string;
  parentId?: string;
}

export interface CommentUpdateInput {
  id: string;
  text: string;
}

export interface CreditInput {
  id?: string;
  canModify: boolean;
  isPublisher?: boolean;
  role: Role;
  share: number;
  title?: string;
  username: string;
}

export interface FileUploadModifications {
  cropCoordinates?: number[];
  duration?: number;
  isHorizontallyFlipped?: boolean;
  rotation?: number;
}

export enum FileFilter {
  'none',
  'keyhole',
  'blur',
  'locked',
}

export enum FilterOperation {
  'upload',
  'delete',
  'replace',
}

export interface FileUpload {
  id?: string;
  mediaItemId?: string;
  caption?: string;
  delete?: boolean;
  file: File;
  previewType?: FileFilter;
  filterIntensity?: number;
  filteredModifiedFile?: File;
  modifications: FileUploadModifications;
  modifiedFile: File;
  previewable: boolean;
  previewSrc?: string;
  result: string;
  type: string;
}

export interface SimpleFile {
  caption?: string;
  src?: string;
  type: string;
}

export interface ImageInput {
  id?: string;
  caption?: string;
  extension: string;
  file?: File;
  previewType?: FileFilter;
  filterIntensity?: number;
  filteredModifiedFile?: File;
  filterOperation?: FilterOperation;
  modifications?: FileUploadModifications;
  previewSrc?: string;
  result?: string;
  modifiedFile?: File;
  src?: string;
  type?: string;
}

export interface MediaCollectionInput {
  id?: string;
  mediaItems: MediaItemInput[];
}

export interface MediaItemInput {
  id?: string;
  order: number;
  previewable: boolean;
  image?: ImageInput;
  video?: VideoInput;
  fileUpload?: FileUpload;
}

export interface ModerationRequestResponseInput {
  id?: string;
  comment?: string;
  hasConfirmed: boolean;
  shouldRemove: boolean;
  moderationRequestId: string;
  moderationRequestResponseFieldRevisions?: ModerationRequestResponseFieldRevisionInput[];
  moderationRequestResponseMediaItemActions?: ModerationRequestResponseMediaItemActionInput[];
  moderationRequestResponseUserActions?: ModerationRequestResponseUserActionInput[];
}

export interface ModerationRequestResponseFieldRevisionInput {
  id?: string;
  field: string;
  original?: string;
  revision: string;
  type?: string;
}

export interface ModerationRequestResponseMediaItemActionInput {
  id?: string;
  shouldDelete: boolean;
  shouldReport: boolean;
  mediaItemId: string;
  mediaItem?: MediaItem;
}

export interface ModerationRequestResponseUserActionInput {
  id?: string;
  activeUntil?: string | Date;
  cannotPostAnything: boolean;
  cannotPostComments: boolean;
  cannotPostContent: boolean;
  cannotModerate: boolean;
  userId: string;
  user?: User;
}

export interface ModerationRequestReportInput {
  comment: string;
  postId?: string;
  commentId?: string;
  curatedListId?: string;
  profileId?: string;
}

export interface ProfileInput {
  id: string;
  avatar?: ImageInput;
  description?: string;
  displayName?: string;
  pronouns?: string;
  website?: string;
}

export interface PurchaseUserSubscriptionInput {
  autorenew: boolean;
  subscriptionDiscountId?: string;
  subscribedId: string;
}

export interface SubscriptionPackageInput {
  subscriptionPrice: number;
  twoMonthPackageEnabled: boolean;
  twoMonthPackage: SubscriptionDiscountInput;
  threeMonthPackageEnabled: boolean;
  threeMonthPackage: SubscriptionDiscountInput;
}

export interface SubscriptionPromotionInput {
  subscriptionPrice: number;
  promotion: SubscriptionDiscountInput;
  promotionEnabled: boolean;
}

export interface SubscriptionDiscountInput {
  duration?: number;
  percentage: number;
  type: SubscriptionDiscountType;
  validSubscriberTypes?: SubscriptionDiscountValidSubscriberType;
}

export interface VideoInput {
  id?: string;
  caption?: string;
  duration: number;
  extension: string;
  file?: File;
  previewType?: FileFilter;
  filterIntensity?: number;
  filteredModifiedFile?: File;
  filterOperation?: FilterOperation;
  modifiedFile?: File;
  modifications?: FileUploadModifications;
  previewSrc?: string;
  result?: string;
  src?: string;
  type?: string;
}
