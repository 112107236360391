import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { goldenRatioInverse } from 'src/utils/math';

const Container = styled.div`
  position: relative;
`;

interface MainProps {
  isExpanded: boolean;
}

const Main = styled.div.attrs((props: MainProps) => ({
  isExpanded: props.isExpanded,
}))`
  overflow: hidden;

  ${(props): string | undefined => {
    if (!props.isExpanded) {
      return `
        height: 2rem;
        cursor: pointer;
      `;
    }
  }}
`;

const ReadMore = styled.div`
  position: absolute;
  top: -3px;
  right: 0;
  padding: 0 1rem;
  color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
  background-color: rgba(${(props): string => props.theme.backgroundColor}, 1);
  cursor: pointer;
  margin-top: ${goldenRatioInverse / 4}rem;
  z-index: 1;
`;

interface InterfaceProps {
  className?: string;
}

const ExpandingContainerView: React.FC<InterfaceProps> = ({
  className,
  children,
}) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const expandSingleLine = useCallback(() => {
    if (ref.current) {
      if (Math.abs(ref.current.scrollHeight - ref.current.offsetHeight) < 50) {
        setIsExpanded(true);
      }
    }
  }, [ref]);

  useEffect(() => {
    expandSingleLine();
  }, [expandSingleLine]);

  useEffect(() => {
    window.addEventListener('resize', expandSingleLine);

    return (): void => {
      window.removeEventListener('resize', expandSingleLine);
    };
  }, [expandSingleLine]);

  return (
    <Container className={className}>
      <Main
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(true)}
        ref={ref}
      >
        {children}
      </Main>
      {!isExpanded && (
        <ReadMore onClick={() => setIsExpanded(true)}>{t('readMore')}</ReadMore>
      )}
    </Container>
  );
};

export default ExpandingContainerView;
