import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { getSessionItem, setSessionItem } from 'src/utils/storage';
import styled from 'styled-components';
import { useOnScreen } from 'src/hooks/use-on-screen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { goldenRatioInverse } from 'src/utils/math';
import { GlobalStyleVariables } from 'src/styles';
import { useLocation } from 'react-router-dom';
import { useUpdateHistoryState } from 'src/hooks/use-update-history-state';
import { debounce } from 'ts-debounce';

interface ContainerProps {
  isFullscreen: boolean;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  isFullscreen: props.isFullscreen,
}))`
  position: relative;

  &:hover {
    div:last-child {
      opacity: 1;
      visibility: visible;
    }
  }

  ${(props): string | undefined => {
    if (props.isFullscreen) {
      return `
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999999;
        background: black;
      `;
    }
  }}
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PlayOverlay = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${goldenRatioInverse * 2};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
  z-index: 2;
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity ${GlobalStyleVariables.baseDuration}ms,
    visibility ${GlobalStyleVariables.baseDuration}ms;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    opacity: 1;
    visibility: visible;
  }
`;

const ControlButton = styled.button`
  width: 4rem;
  font-size: 2.75rem;
  border: 0;
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
  background-color: transparent;
  padding: ${goldenRatioInverse}rem 0;
  opacity: ${goldenRatioInverse};
  cursor: pointer;
  svg {
    filter: drop-shadow(
      3px 5px 2px
        rgba(
          ${(props): string => props.theme.backgroundColor},
          ${goldenRatioInverse}
        )
    );
  }
`;

const PlayPauseButton = styled(ControlButton)`
  padding: 0 1rem;
`;

const MuteButton = styled(ControlButton)`
  padding-right: 1rem;
`;

const ExpandButton = styled(ControlButton)`
  margin-left: auto;
  padding-right: 1rem;
`;

const Progress = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  margin-right: 1rem;
  opacity: ${goldenRatioInverse / 2};
  cursor: pointer;
`;

const ProgressFill = styled.div`
  background-color: rgba(${(props): string => props.theme.primaryColor}, 1);
  height: 1rem;
  margin-bottom: 1rem;
  filter: drop-shadow(
    3px 5px 2px
      rgba(
        ${(props): string => props.theme.backgroundColor},
        ${goldenRatioInverse}
      )
  );
`;

interface PlayerProps {
  canPlay: boolean;
  isFullscreen: boolean;
}

const Player = styled.video.attrs((props: PlayerProps) => ({
  canPlay: props.canPlay,
  isFullscreen: props.isFullscreen,
}))`
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0;
  }

  ${(props): string | undefined => {
    if (!props.canPlay) {
      return `
        height: 1px !important;
        width: 1px !important;

        @media ${GlobalStyleVariables.phoneMediaQuery} {
          width: 100vw !important;
        }
      `;
    }
  }}

  ${(props): string | undefined => {
    if (props.isFullscreen) {
      return `
        max-height: 100svh !important;
        max-width: 100svw !important;
        height: 100svh !important;
        width: 100svw !important;
        z-index: 999999;
      `;
    }
  }}
`;

interface InterfaceProps {
  canClickToPlay?: boolean;
  className?: string;
  shouldMute?: boolean;
  shouldPause: boolean;
  shouldPlayOnView?: boolean;
  src: string;
  uncontrollable?: boolean;
}

const VideoPlayerView: React.FC<InterfaceProps> = ({
  canClickToPlay,
  className,
  shouldMute,
  shouldPause,
  shouldPlayOnView,
  src,
  uncontrollable,
}) => {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const [progressBarContainer, setProgressBarContainer] =
    useState<HTMLDivElement | null>(null);
  const [progressBar, setProgressBar] = useState<HTMLDivElement | null>(null);
  const [playButton, setPlayButton] = useState<HTMLButtonElement | null>(null);
  const [playOverlay, setPlayOverlay] = useState<HTMLButtonElement | null>(
    null
  );
  const isOnScreen = useOnScreen(videoElement);
  const [sessionUnmuted, setSessionUnmuted] = useState(
    getSessionItem('unmuted') === 'true'
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPaused, setIsPaused] = useState(shouldPause);
  const [isMuted, setIsMuted] = useState(!sessionUnmuted);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const location = useLocation();
  const currentTimeId = `video-current-time: ${src}`;
  const state = location.state as { [key: string]: number };
  const initialCurrentTime = (state && state[currentTimeId]) ?? 0;
  const updateHistoryState = useUpdateHistoryState(currentTimeId);

  const changeVolume = useCallback(
    (isUnmuted) => {
      if (!uncontrollable) {
        setSessionUnmuted(isUnmuted);
        setIsMuted(!isUnmuted);
      }
    },
    [uncontrollable]
  );

  const onVolumeChange = useCallback(() => {
    if (videoElement && !uncontrollable) {
      const isUnmuted = !videoElement?.muted;
      changeVolume(isUnmuted);
    }
  }, [changeVolume, videoElement, uncontrollable]);

  const onPlay = () => {
    setIsPaused(false);
  };

  const onPause = () => {
    setIsPaused(true);
  };

  const onProgressStart = () => {
    setIsScrubbing(true);
  };

  const onCanPlay = () => {
    setCanPlay(true);
  };

  const debouncedUpdateHistoryState = debounce((currentTime: number) => {
    if (updateHistoryState) {
      updateHistoryState(currentTime);
    }
  }, 100);

  const onTimeUpdate = useCallback(() => {
    if (videoElement) {
      if (progressBar) {
        const progressPercentage =
          (videoElement.currentTime / videoElement.duration) * 100;
        progressBar.style.flexBasis = `${progressPercentage}%`;
      }
      debouncedUpdateHistoryState(videoElement.currentTime);
    }
  }, [videoElement, progressBar, updateHistoryState]);

  const handleMoveEvent = useCallback(
    (e: Event, clientX: number, endEvent?: boolean) => {
      if (videoElement && progressBarContainer && (endEvent || isScrubbing)) {
        e.preventDefault();
        const scrubTime =
          (clientX / progressBarContainer.offsetWidth) * videoElement.duration;
        videoElement.currentTime = scrubTime;
      }
    },
    [isScrubbing, videoElement, progressBarContainer]
  );

  const onProgressMouseMove = useCallback(
    (e: MouseEvent, endEvent?: boolean) => {
      handleMoveEvent(e, e.offsetX, endEvent);
    },
    [handleMoveEvent]
  );

  const onProgressTouchMove = useCallback(
    (e: TouchEvent, endEvent?: boolean) => {
      const bcr = progressBarContainer?.getBoundingClientRect();
      handleMoveEvent(e, e.changedTouches[0].clientX - (bcr?.x || 0), endEvent);
    },
    [handleMoveEvent]
  );

  const onProgressMouseStopEvent = useCallback(
    (e: MouseEvent) => {
      if (
        e.target === progressBarContainer ||
        (e.target && progressBarContainer?.contains(e.target as HTMLDivElement))
      ) {
        onProgressMouseMove(e, true);
      }
      setIsScrubbing(false);
    },
    [onProgressMouseMove, progressBarContainer]
  );

  const onProgressTouchStopEvent = useCallback(
    (e: TouchEvent) => {
      if (
        e.target === progressBarContainer ||
        (e.target && progressBarContainer?.contains(e.target as HTMLDivElement))
      ) {
        onProgressTouchMove(e, true);
      }
      setIsScrubbing(false);
    },
    [onProgressTouchMove, progressBarContainer]
  );

  const togglePlay = useCallback(
    (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      if (videoElement) {
        if (videoElement.paused || videoElement.ended) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    },
    [videoElement]
  );

  const toggleMute = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (videoElement) {
        e.preventDefault();
        setSessionItem('unmuted', videoElement.muted);
        videoElement.muted = !videoElement.muted;
      }
    },
    [videoElement]
  );

  const toggleFullScreen = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (videoElement) {
        e.preventDefault();

        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else {
          setIsFullscreen(!isFullscreen);
        }
      }
    },
    [videoElement, isFullscreen]
  );

  const onGlobalMuteToggle = useCallback(() => {
    changeVolume(getSessionItem('unmuted') === 'true');
  }, [changeVolume]);

  const preventDefault = useCallback((e: ReactMouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (videoElement) {
      videoElement.addEventListener('pause', onPause);
      videoElement.addEventListener('play', onPlay);
      videoElement.addEventListener('timeupdate', onTimeUpdate);
      videoElement.addEventListener('volumechange', onVolumeChange);
      videoElement.addEventListener('canplay', onCanPlay);
    }
    if (playOverlay) {
      playOverlay.addEventListener('mouseup', togglePlay);
      playOverlay.addEventListener('touchend', togglePlay);
    }
    if (playButton) {
      playButton.addEventListener('mouseup', togglePlay);
      playButton.addEventListener('touchend', togglePlay);
    }
    if (progressBarContainer) {
      progressBarContainer.addEventListener('touchstart', onProgressStart);
      progressBarContainer.addEventListener('mousedown', onProgressStart);
    }
    document.addEventListener('mouseup', onProgressMouseStopEvent, {
      passive: false,
    });
    document.addEventListener('mousemove', onProgressMouseMove, {
      passive: false,
    });
    document.addEventListener('touchend', onProgressTouchStopEvent, {
      passive: false,
    });
    document.addEventListener('touchcancel', onProgressTouchStopEvent, {
      passive: false,
    });
    document.addEventListener('touchmove', onProgressTouchMove, {
      passive: false,
    });
    window.addEventListener(`sessionStorage: unmuted`, onGlobalMuteToggle);
    return () => {
      videoElement?.removeEventListener('play', onPlay);
      videoElement?.removeEventListener('pause', onPause);
      videoElement?.removeEventListener('timeupdate', onTimeUpdate);
      videoElement?.removeEventListener('volumechange', onVolumeChange);
      videoElement?.removeEventListener('canPlay', onCanPlay);
      playOverlay?.removeEventListener('mouseup', togglePlay);
      playOverlay?.removeEventListener('touchend', togglePlay);
      playButton?.removeEventListener('mouseup', togglePlay);
      playButton?.removeEventListener('touchend', togglePlay);
      progressBarContainer?.removeEventListener('mousedown', onProgressStart);
      progressBarContainer?.removeEventListener('touchstart', onProgressStart);
      document.removeEventListener('mouseup', onProgressMouseStopEvent);
      document.removeEventListener('mousemove', onProgressMouseMove);
      document.removeEventListener('touchend', onProgressTouchStopEvent);
      document.removeEventListener('touchcancel', onProgressTouchStopEvent);
      document.removeEventListener('touchmove', onProgressTouchMove);
      window.removeEventListener(`sessionStorage: unmuted`, onGlobalMuteToggle);
    };
  }, [
    videoElement,
    progressBarContainer,
    playButton,
    playOverlay,
    canClickToPlay,
    onGlobalMuteToggle,
    onTimeUpdate,
    onProgressStart,
    onProgressMouseStopEvent,
    onProgressTouchStopEvent,
    onProgressMouseMove,
    onProgressTouchMove,
    onVolumeChange,
    togglePlay,
  ]);

  useEffect(() => {
    if (videoElement) {
      videoElement.currentTime = initialCurrentTime;
    }
  }, [videoElement]);

  useEffect(() => {
    if (videoElement && !shouldPlayOnView) {
      if (shouldPause) {
        videoElement.pause();
      } else {
        videoElement.muted = !(getSessionItem('unmuted') === 'true');
        videoElement.play().catch((e) => {
          if (
            !e.message.startsWith(
              'The play() request was interrupted by a call to pause()'
            )
          ) {
            videoElement.muted = true;
          }

          videoElement.play();
        });
      }
    }
  }, [videoElement, shouldPause, shouldPlayOnView]);

  useEffect(() => {
    if (videoElement) {
      if (shouldPlayOnView) {
        if (isOnScreen) {
          videoElement.muted = !(getSessionItem('unmuted') === 'true');
          videoElement.play().catch((e) => {
            if (
              !e.message?.startsWith(
                'The play() request was interrupted by a call to pause()'
              )
            ) {
              videoElement.muted = true;
            }

            videoElement.play();
          });
        } else {
          videoElement.pause();
        }
      }
    }
  }, [videoElement, isOnScreen, shouldPlayOnView]);

  if (uncontrollable) {
    return (
      <Player
        ref={setVideoElement}
        src={src}
        className={className}
        controls={false}
        muted={true}
        loop
        playsInline
      />
    );
  } else {
    return (
      <Container isFullscreen={isFullscreen}>
        <InnerContainer>
          <Player
            ref={setVideoElement}
            src={src}
            className={className}
            controls={false}
            muted={shouldMute || sessionUnmuted !== true}
            loop
            playsInline
            canPlay={canPlay}
            controlsList="nodownload"
          />
          {canClickToPlay && (
            <PlayOverlay ref={setPlayOverlay}>
              {isPaused && <FontAwesomeIcon icon="play" />}
            </PlayOverlay>
          )}
        </InnerContainer>
        <Controls>
          <PlayPauseButton onClick={preventDefault} ref={setPlayButton}>
            <FontAwesomeIcon icon={isPaused ? 'play' : 'pause'} />
          </PlayPauseButton>
          <MuteButton onClick={toggleMute}>
            <FontAwesomeIcon icon={isMuted ? 'volume-off' : 'volume-up'} />
          </MuteButton>
          <Progress onClick={preventDefault} ref={setProgressBarContainer}>
            <ProgressFill ref={setProgressBar} />
          </Progress>
          <ExpandButton onClick={toggleFullScreen}>
            <FontAwesomeIcon icon={isFullscreen ? 'compress' : 'expand'} />
          </ExpandButton>
        </Controls>
      </Container>
    );
  }
};

export default VideoPlayerView;
