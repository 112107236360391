import React from 'react';
import { ModerationRequest } from 'src/types';
import { useTranslation } from 'react-i18next';
import ModerationRequestItemWrapper from 'src/components/moderation-request-item/moderation-request-item-wrapper';
import { HeadingTwo } from 'src/styles';
import styled from 'styled-components';
import MediaItemCarousel from 'src/components/media-item-carousel';
import PostSummary from 'src/components/post-summary';
import { fibonacci } from 'src/utils/math';
import ModerationRequestItemReports from 'src/components/moderation-request-item/moderation-request-item-reports';

const Carousel = styled(MediaItemCarousel)`
  display: flex;
  height: ${fibonacci(9)}rem;
`;

interface InterfaceProps {
  moderationRequest: ModerationRequest;
}

const ModerationRequestItemPostModerationRequest: React.FC<InterfaceProps> = ({
  moderationRequest,
}) => {
  const { t } = useTranslation('general');
  const post = moderationRequest.post;

  return (
    <ModerationRequestItemWrapper
      moderationRequest={moderationRequest}
      isDisciplinary={false}
      users={post.credits.map((credit) => credit.user)}
      fields={[
        {
          field: 'title',
          original: post.title,
          type: 'input',
        },
        {
          field: 'description',
          original: post.description,
          type: 'textarea',
        },
      ]}
    >
      <HeadingTwo>{t('moderationPostApprovalRequest')}</HeadingTwo>
      <Carousel
        paginationArgs={{
          mediaCollectionId: post.mediaCollection.id,
        }}
      />
      <PostSummary post={post} />
    </ModerationRequestItemWrapper>
  );
};

export default ModerationRequestItemPostModerationRequest;
